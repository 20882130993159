.loading-container {
  position: relative;
  width: 100%;
  height: 100vh;
  /* 设置弹性盒模型 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-trans {
  position: absolute;
  width: 200px;
  height: 200px;
  /* 设置弹性盒模型 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-span {
  position: absolute;
  font-family: '华文中宋', serif;
  font-size: 40px;
  z-index: 1;
}

.loading-trans::after {
  content: "";
  position: absolute;
  width: 220px;
  height: 220px;
  /* 添加边框 */
  border: 4px solid #3ff9dc;
  /* 初始化位置 */
  transform: rotate(45deg);
  /* 添加动画、动画时间和样式 */
  animation: rotate1 3s ease-in-out infinite alternate;
}

.loading-trans::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 220px;
  /* 添加边框 */
  border: 4px solid #ffab91;
  /* 初始化位置 */
  transform: rotate(-90deg);
  /* 添加动画、动画时间和样式 */
  animation: rotate2 3s ease-in-out infinite alternate;
}

@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(-270deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
