body {
  color: var(--semi-color-text-0);
  background-color: rgba(var(--semi-grey-1), 1);

  background-size: cover;
  background-position: center;
  margin: 0;

  font-family: "console", "华文中宋", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /*!*防止iphone转横屏时放大文字, webkit是safari浏览器内核*!*/
  -webkit-font-smoothing: antialiased;
  /*!*可能是用于适配火狐浏览器?*!*/
  -moz-osx-font-smoothing: grayscale;
  // 取消文字的选中
  //user-select: none;
  // 这两个 height 100% 必须都要有，否则无法显示ppt

  #root {
    position: absolute;
    width: 100%;
    height: 100%;
    .App {
      height: 100%;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  // 不允许图片拖动
  -webkit-user-drag: none;
}

h1, h2, h3 {
  font-weight: bold;
}

b {
  font-weight: bold;
}